import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import logger from '../../logger/logger';

const log = logger.scoped('css-transition');

const toClasslist = (input: string[] | string) =>
  (typeof input === 'string' ? input.split(/\s+/) : input).filter((c) =>
    Boolean(c)
  );

function execNextFrame(cb: () => void) {
  if (document.hidden) {
    // If the tab is in the background, prioritize moving to the next state
    // rather than applying the animation visibly.
    cb();
  } else {
    // NOTE: requestAnimationFrame will not fire if the tab is in the
    // background.
    requestAnimationFrame(cb);
  }
}

/**
 * Apply an initial state, then transition to the "entered" state on component
 * mount. This is hard to do with CSS classes only, since you need to wait a
 * frame to allow the initial state to be rendered before transitioning.
 */
export const EnterExitTailwindTransition = (props: {
  initialClasses: string[] | string;
  enteredClasses: string[] | string;
  afterEntered?: (el: HTMLDivElement) => void;
  useEffect?: boolean;
  children: (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    initial: string
  ) => JSX.Element;
}): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const initial = useMemo(
    () => toClasslist(props.initialClasses),
    [props.initialClasses]
  );
  const entered = useMemo(
    () => toClasslist(props.enteredClasses),
    [props.enteredClasses]
  );
  const initialAsString = initial.join(' ');

  const useEffectFunc = props.useEffect ? useEffect : useLayoutEffect;

  const { afterEntered } = props;

  useEffectFunc(() => {
    ref.current?.classList.add(...initial);
    const el = ref.current;

    const [duration] = getTransitionDuration(el);

    const applyEntered = () => {
      el?.classList.remove(...initial);
      timerRef.current = setTimeout(() => afterEnteredCb, duration);
      el?.classList.add(...entered);
    };

    const afterEnteredCb = () => {
      if (!afterEntered || !ref.current) return;
      afterEntered(ref.current);
      ref.current?.removeEventListener('transitionend', afterEnteredCb);
    };

    execNextFrame(applyEntered);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = null;
      el?.classList.remove(...entered);
      el?.classList.add(...initial);
    };
  }, [afterEntered, entered, initial]);

  return props.children(ref, initialAsString);
};

export type Classes = string[] | string;

export interface TailwindTransitionStage<T> {
  classes: T;
  delay?: number;
}

// https://github.com/tailwindlabs/headlessui/blob/e9e6aded545c329585d8cf7452ad9fe400a5406b/packages/%40headlessui-react/src/components/transitions/utils/transition.ts#L23
function getTransitionDuration(node: HTMLElement | null) {
  if (!node) return [0, 0] as const;

  // Safari returns a comma separated list of values, so let's sort them and
  // take the highest value.
  const { transitionDuration, transitionDelay } = getComputedStyle(node);

  const [durationMs, delaysMs] = [transitionDuration, transitionDelay].map(
    (value) => {
      const [resolvedValue = 0] = value
        .split(',')
        // Remove falsy we can't work with
        .filter(Boolean)
        // Values are returned as `0.3s` or `75ms`
        .map((v) => (v.includes('ms') ? parseFloat(v) : parseFloat(v) * 1000))
        .sort((a, z) => z - a);

      return resolvedValue;
    }
  );

  return [durationMs, delaysMs] as const;
}

interface StagedTailwindTransitionProps {
  stages: TailwindTransitionStage<Classes>[];
  onComplete?: (el?: HTMLDivElement) => void;
  debugKey?: string;
  children: (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    initial: string
  ) => JSX.Element;
}

export const StagedTailwindTransition = (
  props: StagedTailwindTransitionProps
): JSX.Element | null => {
  const { debugKey } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const stages = useMemo<TailwindTransitionStage<string[]>[]>(
    () =>
      props.stages.map((s) => {
        return {
          classes: toClasslist(s.classes),
          delay: s.delay,
        };
      }),
    [props.stages]
  );

  const [currentStage, setCurrentStage] = useState(1);
  const onCompleteRef = useRef(props.onComplete);
  useEffect(() => {
    onCompleteRef.current = props.onComplete;
  }, [props.onComplete]);

  const initial = stages.length > 0 ? stages[0].classes.join(' ') : '';

  useEffect(() => {
    const currStageData =
      currentStage < stages.length ? stages[currentStage] : null;
    const prevStageData =
      currentStage - 1 >= 0 ? stages[currentStage - 1] : null;

    if (!timerRef.current && !currStageData) {
      // end of stages
      onCompleteRef.current && onCompleteRef.current(ref.current ?? undefined);
      log.debug(`${debugKey || 'unknown'} finished`, {
        currentStage,
        currStageData,
        prevStageData,
        classes: ref.current?.className,
      });
      return;
    }

    if (timerRef.current || !currStageData || !ref.current) return;

    const [durationMs] = getTransitionDuration(ref.current);

    function applyCurrentStage() {
      if (!ref.current || !currStageData) return;

      log.debug(`${debugKey || 'unknown'} applying current stage`, {
        currentStage,
        currStageData,
        prevStageData,
        classes: ref.current?.className,
      });

      if (prevStageData) ref.current.classList.remove(...prevStageData.classes);
      ref.current.classList.add(...currStageData.classes);
    }

    function nextStage() {
      timerRef.current = null;
      setCurrentStage((s) => {
        const next = s + 1;
        log.debug(`${debugKey || 'unknown'} next stage ${next}`, {
          currentStage,
          currStageData,
          prevStageData,
          classes: ref.current?.className,
        });
        return next;
      });
    }

    if (currStageData.delay) {
      log.debug(
        `${debugKey || 'unknown'} initializing delay ${currStageData.delay}`,
        {
          currentStage,
          currStageData,
          prevStageData,
          classes: ref.current?.className,
        }
      );
      timerRef.current = setTimeout(() => {
        applyCurrentStage();
        timerRef.current = setTimeout(nextStage, durationMs);
      }, currStageData.delay);
    } else {
      // Ensure that the initial style is rendered before moving to the next
      // stage
      execNextFrame(() => {
        applyCurrentStage();
        timerRef.current = setTimeout(nextStage, durationMs);
      });
    }
  }, [currentStage, debugKey, stages]);

  useEffect(() => {
    return () => {
      if (!timerRef.current) return;
      clearTimeout(timerRef.current);
      timerRef.current = null;
    };
  }, []);

  return props.children(ref, initial);
};
